import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Dylan Reeves" keywords={['web developer', 'react']} />
    <h1>Dylan Reeves</h1>
  </Layout>
)

export default IndexPage
